<template>
  <a-row class="content-fix org">
    <a-button
      style="margin-top: 15px"
      type="primary"
      class="org back-btn"
      @click="$router.push({ name: 'pressCenter' })"
    >
      <i class="t-transition icons icon-chevron-left" />
      Назад
    </a-button>

    <a-row class="content-title" type="flex">
      <h2>
        {{ title[$i18n.locale] }}
      </h2>
      <a-button style="margin-left: auto" type="primary" @click="createFunc()">
        <a-icon type="plus" />
        {{ $t("Add") }}
      </a-button>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="categoryProps || []"
      :loading="loading"
      :pagination="{ defaultPageSize: 5 }"
      :row-key="(rec) => rec.id"
    >
      <div
        slot="news_title"
        slot-scope="item"
        class="td-post-item"
        @click="
          $router.push({
            name: 'mass-update',
            params: { id: item.id },
            query: { menu: $route.params.id }
          })
        "
      >
        <b>{{ item.title || "-" }}</b>
        <br />
      </div>

      <template slot="id" slot-scope="item">
        <b>{{ item.id }}</b>
      </template>

      <template slot="title_languages" slot-scope="item">
        <template v-for="(title_lang, key) in item.title_lang_support">
          <a-tag v-if="title_lang" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[key].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[key].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="content_languages" slot-scope="item">
        <template v-for="(content, key) in item.content_lang_support">
          <a-tag v-if="content" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[key].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[key].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="created" slot-scope="item">
        {{ moment.utc(item).format("LLL") }}
      </template>

      <template slot="files" slot-scope="item">
        {{ (item.images && item.images.length) || " - " }}
      </template>

      <template v-if="item" slot="updated" slot-scope="item">
        {{ moment.utc(item).format("LLL") }}
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="language_support" slot-scope="item">
        <template v-for="(languageSupport, key) in item.language_support">
          <a-tag v-if="languageSupport" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[key].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[key].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template v-if="'is_main' in item" slot="is_main" slot-scope="item">
        <a-tag :color="item.is_main ? 'geekblue' : 'volcano'">
          {{ item.is_main ? "Вкл." : "Выкл." }}
        </a-tag>
      </template>

      <template slot="to_slider" slot-scope="item">
        <a-badge
          :status="item.on_slider ? 'success' : 'error'"
          :text="item.on_slider ? 'Да' : 'Нет'"
        />
      </template>

      <template slot="views" slot-scope="item">
        <a-icon type="eye" />
        {{ item.views }}
      </template>

      <template
        v-if="item && 'files' in item"
        slot="files_size"
        slot-scope="item"
      >
        {{
          bytesToSize(Object.values(item.files).reduce((a, b) => a + b.size, 0))
        }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          style="margin-right: 10px"
          type="primary"
          @click="
            $router.push(
              {
                name: 'mass-update',
                params: { id: item.id },
                query: { menu: $route.params.id }
              },
              () => null
            )
          "
        >
          <a-icon class="action-btns" type="edit" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button style="margin-right: 10px" type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>
  </a-row>
</template>

<script>
import { CONTENT_LANGUAGES } from "@/constants"

export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: this.$t("TableHead"),
          width: "50%",
          key: "news_title",
          scopedSlots: { customRender: "news_title" }
        },
        {
          title: this.$t("LangContent"),
          key: "content_languages",
          width: "15%",
          scopedSlots: { customRender: "content_languages" }
        },
        {
          title: this.$t("DateOfPublication"),
          dataIndex: "created",
          key: "created",
          width: "15%",
          scopedSlots: { customRender: "created" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      categoryProps: [],
      title: {
        oz: "СМИ о нас",
        uz: "СМИ о нас",
        ru: "СМИ о нас",
        en: "СМИ о нас"
      }
    }
  },
  computed: {
    contentLanguages: () => CONTENT_LANGUAGES
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
      if (bytes === 0) return "n/a"
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    },
    getFirstFulfilled(fields) {
      let lang = this.$i18n.locale
      if (!fields) return {}
      if (fields[lang]) {
        return {
          text: fields[lang],
          lang: lang
        }
      } else {
        const lang = Object.keys(fields).find((l) => fields[l])
        if (!lang) {
          return {}
        }
        return {
          text: fields[lang],
          lang: lang
        }
      }
    },
    async fetchData() {
      this.loading = true

      try {
        const r = await this.$store.dispatch("posts/fetch", {
          menu: this.$route.params && this.$route.params.id
        })

        this.$set(this, "categoryProps", [...r])
      } catch (e) {
        this.$sentry.captureMessage(e)
      }

      this.loading = false
    },
    async removeItem(id) {
      try {
        const res = await this.$store.dispatch("posts/deletePost", id)
        if (res && res.status === 204) {
          await this.fetchData()
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
    },
    async createFunc() {
      let res = await this.$api.post("/admin/post/create/", {
        menu: this.$route.params.id,
        is_active: true
      })
      await this.$router.push({
        name: "mass-update",
        params: { id: res && res.data && res.data.id },
        query: { menu: this.$route.params.id }
      })
    }
  }
}
</script>
